class window.Product
  constructor: ->

    @_$container = $('.product')
    @_$wrap = $('.product').parent('.product--wrap')
    @_imageHeight = null

    @_$revealOrder = $('#reveal-order')

    @_$orderTitle = $('.order__title')
    @_$orderPrice = $('.order__total__price')
    @_$orderImage = $('.order__image')
    @_$orderTitleInput = $('#input-order-title')
    @_$orderPriceInput = $('#input-order-total-price')
    @_$orderAppsInput = $('input[type="checkbox"]')

    @_events()

  show: (el) ->
    @hide()
    @_$container.html el.html()

    @_changeImage()
    @_initGallery()

  hide: () ->
    @_setImageHeight()
    @_$container.html ''

  _events: ->
    @_$revealOrder.on 'open.zf.reveal', (event) =>
      form = $(event.currentTarget).find('form')

      @_resetForm form

      $('.accordion', form).foundation('up', $('.accordion .accordion-content', form))

      @_setOrderForm()

    @_$orderAppsInput.on 'change', (e) =>
      el = $(e.currentTarget)
      relatedField = $('#' + $(e.currentTarget).parents('.form__element').data('app-related-field'))

      if el.is(':checked')
        price = parseFloat(@_$orderPrice.text()) + parseFloat(el.data('app-init-price'))
        @_$orderPrice.text price
        @_$orderPriceInput.val price
      else
        price = parseFloat(@_$orderPrice.text()) - parseFloat(el.data('app-init-price'))
        @_$orderPrice.text price
        @_$orderPriceInput.val price

      if relatedField.length
        relatedField.slideToggle(250)

  _setOrderForm: ->
    $title = $('.product__title', @_$container)
    $price = $('.product__price .price', @_$container)
    $image = $('.product__image img', @_$container).clone()

    @_$orderTitle.text $title.text()
    @_$orderPrice.text $price.text().replace(' ', '')
    @_$orderPriceInput.val $price.text().replace(' ', '')
    @_$orderImage.html $image[0]
    @_$orderTitleInput.val $title.text()

  _setImageHeight: ->
    @_imageHeight = $('.product__image', @_$container).height()

  _changeImage: ->
    imageWrap = $('.product__image', @_$container)
    imageWrap.addClass('loading').height @_imageHeight

    imageWrap.find('img').on 'load', ->
      imageWrap.removeClass('loading').height ''

  _initGallery: ->
    image = $('.product__image img', @_$container)
    imageLink = $('.product__image a', @_$container)

    thumb = $('.product__thumbs', @_$container)
    thumbLink = $('a', thumb)

    if thumbLink.length
      modalImages = []
      thumbLink.each (i, el) ->
        that = $(el)
        modalImages.push
          href: that.attr 'href'
          title: that.attr 'title'
          imagePreview: that.data 'image-preview'
        return

      thumbLink.on 'click', (e) =>
        e.preventDefault()

        that = $(e.currentTarget)
        parent = that.parent()
        parentIndex = parent.index()

        href = that.attr 'href'
        preview = that.data 'image-preview'
        title = that.attr 'title'

        @_setImageHeight()

        $('.product-thumb', @_$container).removeClass 'active'
        parent.addClass 'active'

        imageLink.attr
          'href': href
          'title': title
          'data-index': parentIndex

        image.attr
          'src': preview
          'alt': title

        @_changeImage()

      imageLink.on 'click', (e) ->
        e.preventDefault()

        $.fancybox.open modalImages,
          index: $(e.currentTarget).attr('data-index')
          padding: [50, 60, 30, 60]
          type: 'image'
          tpl:
            closeBtn: '<a title="Закрыть" class="fancybox-item fancybox-close" href="javascript:;"></a>'
            next: '<a title="Следующее изображение" class="fancybox-nav fancybox-next" href="javascript:;"><span></span></a>'
            prev: '<a title="Предыдущее изображение" class="fancybox-nav fancybox-prev" href="javascript:;"><span></span></a>'
          helpers:
            title:
              type: 'inside'
            overlay:
              locked: true

  _resetForm: (form) ->
    if form.length
      form[0].reset()
      $('[type="file"]', form).parent('.input-file').find('span').text('Загрузите свой эскиз').attr 'title', 'Загрузите свой эскиз'
      $('.form__element--related', form).css 'display', 'none'